$(document).ready(function () {
	hashtag=window.location.hash;
    // BS 5 Navigation Button / Offcanvas
    document.querySelector('.second-button').addEventListener('click', function () {
        document.querySelector('.animated-icon2').classList.toggle('open');
    });

    jQuery('body').on('click', '.offcanvas-backdrop', function() {
        jQuery('.animated-icon2').removeClass('open');
    });
    jQuery('body').on('click', '.btn-close-button', function() {
        jQuery('.animated-icon2').removeClass('open');
    });

    // Global enable ToolTip
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })    

    // Add minus icon for collapse element which is open by default
    $(".collapse.show").each(function(){
        $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
    });
    
    // Toggle plus minus icon on show hide of collapse element
    $(".collapse").on('show.bs.collapse', function(){
        $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
    }).on('hide.bs.collapse', function(){
        $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
    });

    // Call Gridder
    $(".gridder").gridderExpander({
        scrollOffset: 60,
        scrollTo: "panel",
        animationSpeed: 0,
        /*animationEasing: "easeInOutExpo",*/
        nextText: "&rarr; ",
        prevText: "&larr; ",
        closeText: "&times; "
    });
	
	//jQuery(hashtag).click();
    $(hashtag).trigger("click");

    // Filtering Card Overlay
    $(".more-btn").on('click', function () {
        $(this).parents(".custom-card").find(".more-text").toggleClass("active")
    });
    
    // Filtering Gallery
    $(".filter-button").click(function () {
        var value = $(this).attr('data-filter');

        if (value == "all") {
            $('.filter').show('1000');
        }

        else {
            $(".filter").not('.' + value).hide('4000');
            $('.filter').filter('.' + value).show('4000');
        }
    });

    $(".btn").each(function () {
        $(this).click(function () {
            $(this).addClass("active");
            $(this).siblings().removeClass("active");
        });
    });

});

 $(window).scroll(function() {
        if ($(this).scrollTop() > 50 ) {
            $('.scrolltop:hidden').stop(true, true).fadeIn();
        } else {
            $('.scrolltop').stop(true, true).fadeOut();
        }
    });
    $(function(){$(".scroll").click(function(){$("html,body").animate({scrollTop:$(".thetop").offset().top},"1000");return false})})    